import React, { useState, useRef, useEffect } from "react";
import { useClickOutside } from "hooks/use-click-outside/use-click-outside";

import {
  StyledUserProfile,
  MobileStyledUserProfile,
  UserAvatar,
  UserDetails,
  UserStyledChevron,
  UserName,
  UserSubDetails,
  UserDropdownMenu,
  UserDropdownMenuItem,
  ChangeProfile,
  UserPremiumBadge
} from "./user-profile.styles";
import userProfileImage from "public/assets/home/default-user-profile.png";

import { IUserProfileProps, SelectedOption } from "./user-profile.types";
import { ProfileDetailsModal } from "./profile-details-modal/profile-details-modal";
import { SelectProfileModal } from "./select-profile-modal/select-profile-modal";
import { PremiumIcon } from "../../kelas-live/assets/premium";
import { useKelasLiveContext } from "../../../stores/kelas-live/kelas-live-context";
import { SubscriptionType } from "../../../services/api/sessions/sessionService.types";
import { Chevron } from "components/icons/chevron";

const UserProfile: React.FC<IUserProfileProps> = ({
  userData,
  setUserData,
  signOutUser
}) => {
  const [isUserProfileSelected, setIsUserProfileSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SelectedOption | null>(
    null
  );
  const { full_name, grade, class_type, stream, access_token, avatar_url } =
    userData.primary_user;
  const ref = useRef(null);
  const handleClickOutside = () => {
    setIsUserProfileSelected(false);
  };
  const { userSubscription } = useKelasLiveContext();

  useEffect(() => {
    userSubscription.fetchSubscriptionStatus(access_token);
  }, []);

  useClickOutside(ref, handleClickOutside);

  return (
    <>
      <StyledUserProfile
        data-testid="user-profile"
        onClick={() => setIsUserProfileSelected((prevValue) => !prevValue)}
        ref={ref}
      >
        <UserAvatar
          src={avatar_url || userProfileImage}
          alt={`{${full_name}} Profile Image`}
          objectFit="cover"
          layout="fixed"
          height="40"
          width="40"
        />
        {userSubscription.subscriptionStatus?.subscriptionType ===
          SubscriptionType.PAID && (
          <UserPremiumBadge>
            <PremiumIcon />
          </UserPremiumBadge>
        )}
        <UserDetails>
          <UserName>{full_name}</UserName>
          <UserSubDetails>
            {class_type} {grade} {stream}
          </UserSubDetails>
        </UserDetails>
        <Chevron
          width={15}
          height={15}
          direction={isUserProfileSelected ? "up" : "down"}
        />
        {isUserProfileSelected && (
          <UserDropdownMenu>
            <UserDropdownMenuItem
              onClick={() => setSelectedOption(SelectedOption.ProfileDetails)}
              data-testid="profile-details-button"
            >
              Profilku
              <Chevron width={15} height={15} direction={"right"} />
            </UserDropdownMenuItem>
            {userData.student_users?.length > 1 && (
              <UserDropdownMenuItem
                onClick={() => setSelectedOption(SelectedOption.SelectProfile)}
                data-testid="ganti-profile-button"
              >
                Ganti profil
                <Chevron width={15} height={15} direction={"right"} />
              </UserDropdownMenuItem>
            )}
          </UserDropdownMenu>
        )}
      </StyledUserProfile>
      {selectedOption === SelectedOption.ProfileDetails && (
        <ProfileDetailsModal
          isOpen={selectedOption === SelectedOption.ProfileDetails}
          onClose={() => setSelectedOption(null)}
          heading="Profil"
          userData={userData}
          signOutUser={signOutUser}
        />
      )}
      {selectedOption === SelectedOption.SelectProfile && (
        <SelectProfileModal
          isOpen={selectedOption === SelectedOption.SelectProfile}
          onClose={() => setSelectedOption(null)}
          heading="Ganti profil"
          userData={userData}
          setUserData={setUserData}
        />
      )}
    </>
  );
};

const MobileUserProfile: React.FC<IUserProfileProps> = ({
  userData,
  setUserData,
  signOutUser
}) => {
  const { full_name, grade, class_type, stream } = userData.primary_user;
  const [selectedOption, setSelectedOption] = useState<SelectedOption | null>(
    null
  );

  return (
    <>
      <MobileStyledUserProfile
        data-testid="mobile-user-profile"
        onClick={() => setSelectedOption(SelectedOption.ProfileDetails)}
      >
        <UserAvatar
          src={userProfileImage}
          alt={`{${full_name}} Profile Image`}
          objectFit="cover"
          layout="fixed"
          height="40"
          width="40"
        />
        <UserDetails>
          <UserName>{full_name}</UserName>
          <UserSubDetails>
            {class_type} {grade} {stream}
          </UserSubDetails>
        </UserDetails>
        <UserStyledChevron>
          <Chevron width={15} height={15} direction={"right"} />
        </UserStyledChevron>
      </MobileStyledUserProfile>
      {userData?.student_users?.length > 1 && (
        <ChangeProfile
          onClick={() => setSelectedOption(SelectedOption.SelectProfile)}
          data-testid="ganti-profile-button"
        >
          Ganti Profil
        </ChangeProfile>
      )}
      <ProfileDetailsModal
        isOpen={selectedOption === SelectedOption.ProfileDetails}
        onClose={() => setSelectedOption(null)}
        heading="Profil"
        userData={userData}
        signOutUser={signOutUser}
      />
      <SelectProfileModal
        isOpen={selectedOption === SelectedOption.SelectProfile}
        onClose={() => setSelectedOption(null)}
        heading="Ganti profil"
        userData={userData}
        setUserData={setUserData}
      />
    </>
  );
};

export { UserProfile, MobileUserProfile };
