import { useMediaQuery } from "hooks/use-media-query/use-media-query";
import * as Sentry from "@sentry/react";
import Link from "next/link";
import { useRouter } from "next/router";

import React, { useEffect, useState } from "react";
import { eventNames } from "services/mixpanel/events";
import trackEvent from "services/mixpanel/mixpanel";
import { getUserProfiles } from "services/api/users/user-services";
import { useAuth } from "stores/auth/auth-context";
import { useCookies } from "react-cookie";
import {
  getPageNameFromPath,
  isKelasLivePage,
  isMarketingPage
} from "utils/utils";
import { constants } from "../../constants/constants";

import { IPrimaryAuthData } from "../../services/api/auth/authService.types";

import { Close } from "components/icons/close";
import { Hamburger } from "components/icons/hamburger";
import { Logo } from "../logo/logo";
import {
  DownloadButton,
  HamburgerIcon,
  HeaderCTA,
  HeaderContainer,
  HeaderMenuCTA,
  Menu,
  MenuContainer,
  MenuDivider,
  MenuItem,
  MenuLink,
  MobileMenuContainer,
  StyledHeader,
  MobileUserProfileContainer
} from "./header.styles";
import { IHeaderProps } from "./header.types";
import { mergeArraysByKey } from "utils/utils";
import { MobileUserProfile, UserProfile } from "./user-profile/user-profile";

const Header: React.FC<IHeaderProps> = ({ hidesMenu = false }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);

  const router = useRouter();

  const { user, signOutUser } = useAuth();
  const { userData, setUserData, isUserLoggedIn } = user || {};

  const isMobile = useMediaQuery("mobile");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_cookies, setCookie, removeCookie] = useCookies();

  const handleLoginEvent = () => {
    trackEvent({
      eventName: eventNames.loginButtonClicked,
      payload: {
        incomingSource: "website",
        eventSource: "website",
        loginFromPage: getPageNameFromPath(router.pathname),
        isOnboarded: null
      }
    });
    router.push({
      pathname: "/login",
      query: router.query
    });
  };

  const handleCookieUpdate = (key: string, data: object) => {
    removeCookie(key, { path: "/" });
    setCookie(key, JSON.stringify(data), { path: "/" });
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        if (userData?.primary_user?.access_token) {
          const userResponse = await getUserProfiles(
            userData.primary_user.access_token
          );
          const { data: user } = userResponse;

          const newUserData = {
            ...userData,
            ...user,
            primary_user: { ...userData.primary_user, ...user.primary_user },
            student_users: mergeArraysByKey(
              userData.student_users,
              user.student_users,
              "id"
            ),
            parent_user: { ...userData.parent_user, ...user.parent_user }
          };

          if (newUserData) {
            // Split the cookie because of the cookie size limit
            const primaryAuthData: IPrimaryAuthData = { ...newUserData };
            delete primaryAuthData.student_users;
            delete primaryAuthData.parent_user;
            handleCookieUpdate(
              constants.userContext.cookieKey,
              primaryAuthData
            );
            handleCookieUpdate(constants.userContext.cookieKeyStudent, {
              student_users: newUserData.student_users
            });
            handleCookieUpdate(constants.userContext.cookieKeyParent, {
              parent_user: newUserData.parent_user
            });
            Sentry.setUser(null);
            Sentry.setUser({
              id: newUserData.primary_user.id,
              email: newUserData.primary_user.email,
              username: newUserData.primary_user.student_id,
              name: newUserData.primary_user.full_name
            });
            setUserData(newUserData);
          }
        }
      } catch (err) {
        Sentry.withScope((scope) => {
          scope.setTag("where", "Header");
          scope.setExtra("info", { step: "fetchStudentData" });
          Sentry.captureException(err);
        });
      }
    };

    fetchStudentData();
  }, []);

  const handleHamburgerIconClick = () => {
    setMenuVisible(!isMenuVisible);
  };

  const kelasLivePage = isKelasLivePage(router.pathname);

  const hideMenuItems = isMarketingPage(router.pathname);

  return (
    <StyledHeader>
      <HeaderContainer>
        {!hidesMenu && !hideMenuItems && (
          <HamburgerIcon
            onClick={handleHamburgerIconClick}
            data-testid="header-burger-button"
          >
            {isMenuVisible ? <Close /> : <Hamburger />}
          </HamburgerIcon>
        )}
        <Logo />
        {(kelasLivePage || isMobile) && (
          <Link
            href="https://colearn.app.link/DownloadCoLearn"
            passHref
            legacyBehavior
          >
            <DownloadButton>Download</DownloadButton>
          </Link>
        )}

        {!hideMenuItems && (
          <MobileMenuContainer $active={isMenuVisible}>
            <div>
              {isUserLoggedIn && (
                <MobileUserProfileContainer>
                  <MobileUserProfile
                    userData={userData}
                    signOutUser={signOutUser}
                    setUserData={setUserData}
                  />
                </MobileUserProfileContainer>
              )}
              {isUserLoggedIn ? <MenuDivider /> : null}
            </div>

            <Menu>
              <MenuItem>
                <MenuLink
                  data-testid="bimbel-header-button"
                  href="/bimbel"
                  title="Bimbel"
                >
                  Bimbel
                </MenuLink>
              </MenuItem>
              <MenuItem>
                <MenuLink
                  data-testid="tanya-header-button"
                  href="/tanya"
                  title="Tanya"
                >
                  Tanya
                </MenuLink>
              </MenuItem>
            </Menu>
            <MenuDivider />
            <Menu>
              <MenuItem>
                <MenuLink
                  href="/blog/mengajar/gurujuara"
                  title="Ngajar di CoLearn"
                  data-testid="ngajar-header-button"
                >
                  Ngajar di CoLearn
                </MenuLink>
              </MenuItem>
              <MenuItem>
                <MenuLink
                  href="/jadwal"
                  title="Jadwal dan Materi"
                  data-testid="jadwal-header-button"
                >
                  Jadwal dan Materi
                </MenuLink>
              </MenuItem>
            </Menu>
            {isUserLoggedIn ? (
              <HeaderMenuCTA onClick={() => signOutUser()}>
                Keluar
              </HeaderMenuCTA>
            ) : (
              <Link
                href="https://colearn.app.link/DownloadCoLearn"
                passHref
                legacyBehavior
              >
                <DownloadButton $isFullWidth>Download</DownloadButton>
              </Link>
            )}
          </MobileMenuContainer>
        )}

        {!hidesMenu && !hideMenuItems && (
          <>
            <MenuContainer>
              <Menu>
                <MenuItem>
                  <MenuLink
                    data-testid="bimbel-header-button"
                    href="/bimbel"
                    title="Bimbel"
                  >
                    Bimbel
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink
                    data-testid="tanya-header-button"
                    title="Tanya"
                    href="/tanya"
                  >
                    Tanya
                  </MenuLink>
                </MenuItem>
              </Menu>
              <Menu>
                <MenuItem>
                  <MenuLink
                    href="/blog/mengajar/gurujuara"
                    title="Ngajar di CoLearn"
                    data-testid="ngajar-header-button"
                  >
                    Ngajar di CoLearn
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink
                    href="/jadwal"
                    title="Jadwal dan Materi"
                    data-testid="jadwal-header-button"
                  >
                    Jadwal dan Materi
                  </MenuLink>
                </MenuItem>
              </Menu>
            </MenuContainer>

            {isUserLoggedIn ? (
              <UserProfile
                userData={userData}
                signOutUser={signOutUser}
                setUserData={setUserData}
              />
            ) : (
              !isMenuVisible &&
              !isMobile && (
                <HeaderCTA $placeRight onClick={handleLoginEvent}>
                  Masuk
                </HeaderCTA>
              )
            )}
          </>
        )}
      </HeaderContainer>
    </StyledHeader>
  );
};

export { Header };
